import { Component } from '@angular/core';
import { ROUTES } from './side-nav-routes.config';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { Roles } from 'src/app/apps/models/roles';

@Component({
    selector: 'app-sidenav',
    templateUrl: './side-nav.component.html'
})

export class SideNavComponent{

    roles: Roles = new Roles();
    public menuItems: any[]
    isFolded : boolean;
    isSideNavDark : boolean;
    isExpand : boolean;

    constructor( private themeService: ThemeConstantService) {}

    ngOnInit(): void {
        let exampleROl = localStorage.getItem('rol')
        let example = this.roles[exampleROl]
        if (exampleROl === "Estudiante") {
            this.menuItems = this.roles.Estudiante;
        } else if (exampleROl === "Profesor") {
            this.menuItems = this.roles.Profesor;
        } else {
            this.menuItems = this.roles.Admin;
        }

        
        // console.log(this.roles);
        // console.log(localStorage.getItem('rol'));
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
        this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    }

    closeMobileMenu(): void {
        if (window.innerWidth < 992) {
            this.isFolded = false;
            this.isExpand = !this.isExpand;
            this.themeService.toggleExpand(this.isExpand);
            this.themeService.toggleFold(this.isFolded);
        }
    }
}
