
<div>
    <h5 class="m-b-0">Side Nav Dark</h5>
    <p>Change Side Nav to dark</p>
    <div class="m-t-10">
        <nz-switch [(ngModel)]="isSideNavDark" (ngModelChange)="toggleSideNavDark()"></nz-switch>
    </div>
</div>
<nz-divider></nz-divider>
<div>
    <h5 class="m-b-0">Folded Menu</h5>
    <p>Toggle Folded Menu</p>
    <div class="m-t-10">
        <nz-switch [(ngModel)]="isFolded" (ngModelChange)="toggleFold()"></nz-switch>
    </div>
</div>
