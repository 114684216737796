
export class Roles{
    Admin: any;
    Estudiante: any;
    Profesor: any;
    
    constructor() {
        this.Admin =[ {
            
                path: '',
                title: 'Estudiante',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'user',
                submenu: [
                    {
                        path: '/estudiantes/registro-estudiante',
                        title: 'Registro',
                        iconType: 'nzIcon',
                        icon: 'form',
                        iconTheme: 'outline',
                        submenu: []
                    },
                    {
                        path: '/estudiantes/consulta-estudiante',
                        title: 'Consulta',
                        iconType: 'nzIcon',
                        icon: 'file-search',
                        iconTheme: 'outline',
                        submenu: []
                    },
                    {
                        path: '/estudiantes/consulta-horario-estudiante',
                        title: 'Horario',
                        iconType: 'nzIcon',
                        icon: 'schedule',
                        iconTheme: 'outline',
                        submenu: []
                    }
                ]
        },
        {
            path: '',
            title: 'Docentes',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'skin',
            submenu: [
                {
                    path: '/docentes/registro-docente',
                    title: 'Registro',
                    iconType: 'nzIcon',
                    icon: 'form',
                    iconTheme: 'outline',
                    submenu: []
                },
                {
                    path: '/docentes/consulta-docente',
                    title: 'Consulta',
                    iconType: 'nzIcon',
                    icon: 'file-search',
                    iconTheme: 'outline',
                    submenu: []
                },
            ]
            },
            {
                path: '',
                title: 'Asignaturas',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'user',
                submenu: [
                    {
                        path: '/asignatura/registro-asignatura',
                        title: 'Registro',
                        iconType: 'nzIcon',
                        icon: 'file-add',
                        iconTheme: 'outline',
                        submenu: []
                    },
                    {
                        path: '/asignatura/consulta-asignatura',
                        title: 'Consulta',
                        iconType: 'nzIcon',
                        icon: 'file-search',
                        iconTheme: 'outline',
                        submenu: []
                    },
                ]
            },
        ],
        this.Estudiante = [{

            path: '',
            title: 'Estudiante',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'user',
            submenu: [
                {
                    path: '/estudiantes/consulta-horario-estudiante',
                    title: 'Horario',
                    iconType: 'nzIcon',
                    icon: 'schedule',
                    iconTheme: 'outline',
                    submenu: []
                }
            ]
            }]
        this.Profesor = [{
            path: '',
            title: 'Docentes',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'skin',
            submenu: [
                {
                    path: '/docentes/registro-horarios',
                    title: 'Horario',
                    iconType: 'nzIcon',
                    icon: 'file-search',
                    iconTheme: 'outline',
                    submenu: []
                },
            ]
            },]


        
           
    }
}

   
 

  
