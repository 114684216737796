<div class="header">
    <div class="logo logo-dark">
        <a href="">
            <img src="assets/images/logo/logo.png" alt="Logo">
            <img class="logo-fold" src="assets/images/logo/logo-fold.png" alt="Logo">
        </a>
    </div>
    <div class="logo logo-white">
        <a href="">
            <img src="assets/images/logo/logo-white.png" alt="Logo">
            <img class="logo-fold" src="assets/images/logo/logo-fold-white.png" alt="Logo">
        </a>
    </div>
    <div class="nav-wrap">
        <ul class="nav-left">
            <li class="desktop-toggle">
                <a (click)="toggleFold()">
                    <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" theme="outline"></i>
                </a>
            </li>
            <li class="mobile-toggle">
                <a (click)="toggleExpand()">
                    <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" theme="outline"></i>
                </a>
            </li>
            <!-- <li>
                <a (click)="searchToggle()">
                    <i nz-icon nzType="search" theme="outline"></i>
                </a>
                <nz-drawer [nzWidth]="380" nzTitle="Search" [nzVisible]="searchVisible" nzPlacement="left" (nzOnClose)="searchToggle()">
                    <app-search></app-search>
                </nz-drawer>
            </li> -->
        </ul>
        <ul class="nav-right">
            <li>
                <span class="p-h-10 pointer" nz-dropdown [nzDropdownMenu]="profile" [nzTrigger]="'click'" [nzPlacement]="'bottomRight'">
                    <nz-avatar [nzIcon]="'user'" nzSrc="{{users?.usuario.foto}}" style="border-color: aqua;"></nz-avatar>
                </span>
                <nz-dropdown-menu #profile="nzDropdownMenu">
                    <ul nz-menu class="p-b-15 p-t-20">
                        <li class="p-h-20 p-b-15 m-b-10 border-bottom">
                            <div class="d-flex m-r-50">
                                <nz-avatar nzSize="large" nzSrc="{{users?.usuario.foto}}"></nz-avatar>
                                <div class="m-l-10">
                                    <p class="m-b-0 text-dark font-weight-semibold">{{users?.usuario.nombres}} {{users?.usuario.apellidos}}</p>
                                    <p class="m-b-0 opacity-07">{{users?.usuario.carrera}}</p>
                                </div>
                            </div>
                        </li>
                        <!-- <li nz-menu-item>
                            <a class="p-v-5 d-flex align-items-center justify-content-between">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="user" theme="outline"></i>
                                    <span class="m-l-10">Edit Profile</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li> -->
                        <li nz-menu-item (click)="logout()">
                            <a class="p-v-5 d-flex align-items-center justify-content-between" >
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="logout" theme="outline"></i>
                                    <span class="m-l-10">Logout</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>
                    </ul>
                </nz-dropdown-menu >
            </li>
            <li>
                <a (click)="quickViewToggle()">
                    <i nz-icon nzType="appstore" theme="outline"></i>
                </a>
                <nz-drawer [nzWidth]="280" nzTitle="Theme Config" [nzClosable]="false" [nzVisible]="quickViewVisible" nzPlacement="right" (nzOnClose)="quickViewToggle()">
                    <ng-container *nzDrawerContent>
                        <app-quick-view></app-quick-view>
                    </ng-container>
                </nz-drawer>
            </li>
        </ul>
    </div>
</div>    