import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HandleHttpErrorService } from './handle-http-error.service';
import { Observable } from 'rxjs';
import { Users } from '../models/users';
import { Usuario } from '../models/usuario';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  baseUrl: string;
  constructor(
    private http: HttpClient,
    private handleErrorService: HandleHttpErrorService) {
    this.baseUrl = "https://asistencias-apigateway-gwgjatgad7g4fmbg.eastus2-01.azurewebsites.net/matriculas/";
  }



  getUsers(): Observable<Users> {
    const url = `${this.baseUrl}api/Users/BuscarPorEmail/${localStorage.getItem('userName')}`;
    const token = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token ? `Bearer ${token}` : ''
      }),
    };
    return this.http.get<Users>(url, httpOptions);
  } 
}
