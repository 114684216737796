import { Component } from '@angular/core';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/apps/services/user.service';
import { Users } from 'src/app/apps/models/users';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})

export class HeaderComponent{

    searchVisible : boolean = false;
    quickViewVisible : boolean = false;
    isFolded : boolean;
    isExpand: boolean;
    users: Users;

    constructor( private themeService: ThemeConstantService, private router: Router, private userServices:UserService) {}

    ngOnInit(): void {
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
        this.usuariLogueado();
    }

    toggleFold() {
        this.isFolded = !this.isFolded;
        this.themeService.toggleFold(this.isFolded);
    }

    toggleExpand() {
        this.isFolded = false;
        this.isExpand = !this.isExpand;
        this.themeService.toggleExpand(this.isExpand);
        this.themeService.toggleFold(this.isFolded);
    }

    searchToggle(): void {
        this.searchVisible = !this.searchVisible;
    }

    quickViewToggle(): void {
        this.quickViewVisible = !this.quickViewVisible;
    }

    logout(){
        localStorage.clear();
        this.router.navigate(['/authentication/login-3']);
    }
    
    usuariLogueado(){
         this.userServices.getUsers().subscribe(
            (a) => {
                
                this.users = a;
                localStorage.setItem("identificacion", this.users.usuario.identificacion)
            }
        );
    }
}
